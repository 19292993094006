import { BlobServiceClient, BlockBlobClient } from "@azure/storage-blob";

const getResourceUrl = (resourceName) => {
  if (resourceName != null) {
    return `https://${process.env.REACT_APP_AZURE_ACCOUNT_NAME}.blob.core.windows.net/${process.env.REACT_APP_AZURE_CONTAINE_NAME}/${resourceName}`;
  }
  return `https://${process.env.REACT_APP_AZURE_ACCOUNT_NAME}.blob.core.windows.net/${process.env.REACT_APP_AZURE_CONTAINE_NAME}`;
};

const getConnectionURL = (resourceName) => {
  const base = getResourceUrl(resourceName);
  const sas = process.env.REACT_APP_AZURE_SAS;
  return `${base}?${sas}`;
};

const generateUUID = () => {
  var d = new Date().getTime();
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0;
  return "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const uploadFile = async (file, fileName) => {
  try {
    const extension = "." + fileName.split(".").pop();
    const uuidFileName = generateUUID();
    const cn = getConnectionURL(uuidFileName + extension);
    const blobBlockClient = new BlockBlobClient(cn);

    const res = await blobBlockClient.uploadBrowserData(file);

    return { response: res, uuidFileName: uuidFileName + extension };
  } catch (error) {
    console.log(error);
    return { response: null, uuidFileName: null };
  }
};

export const deleteFile = async (fileName) => {
  try {
    const cn = getConnectionURL();
    const blobBlockClient = new BlobServiceClient(cn);
    const res = await blobBlockClient
      .getContainerClient("")
      .deleteBlob(fileName);
    return res;
  } catch (error) {
    console.log(error);
  }
};
// dowload file
export const dowloadFile = async (fileName) => {
  try {
    const cn = getConnectionURL();
    const blobBlockClient = new BlobServiceClient(cn);
    const res = await blobBlockClient
      .getContainerClient("")
      .getBlobClient(fileName)
      .download();
    let respo = await res.blobBody;
    return respo;
  } catch (error) {
    console.log(error);
  }
};
